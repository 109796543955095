<template>
  <div class="d-flex flex-wrap align-center" style="gap: 1rem">
    <cz-chip
      v-for="item of filtersWithValues(value)"
      :key="item.fieldname"
      removeable
      color="buttonSecondary"
      @remove="onTagRemove(item)"
      small
    >
      <div class="d-flex" style="column-gap: 2px">
        <span>{{ item.label }}:</span>
        <span
          class="font-weight-semibold text-truncate"
          style="max-width: 250px"
          :title="formatItemForDisplay(item, value)"
          >{{ formatItemForDisplay(item, value) }}</span
        >
      </div>
    </cz-chip>
  </div>
</template>

<script>
import {
  filtersWithValues,
  formatItemForDisplay
} from '@/shared/services/filters.service';
import { CzChip } from '@/components';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'FiltersTags',
  components: {
    CzChip
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filters: []
    };
  },
  methods: {
    filtersWithValues,
    formatItemForDisplay,
    onTagRemove(item) {
      const _value = cloneDeep(this.value);
      const field = _value.find(
        (filterItem) => filterItem.fieldname === item.fieldname
      );
      if (field) {
        field.value = '';
      }

      this.$emit('input', _value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
