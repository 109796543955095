<template>
  <v-form class="position-relative" v-if="localForm">
    <v-row>
      <v-col :cols="12">
        <v-card-title class="text-h5 textPrimary--text font-weight-semibold">{{
          $t('vendor.onboarding.vendorDocuments')
        }}</v-card-title>
      </v-col>
    </v-row>
    <v-row v-for="group in fieldGroups" :key="group.title" align="start">
      <v-col :cols="12">
        <v-card-title class="font-weight-semibold">{{
          group.title
        }}</v-card-title>
      </v-col>
      <v-col
        :md="6"
        :lg="3"
        :sm="12"
        v-for="file in group.fields"
        :key="file.id"
      >
        <cz-form-field
          :label="file.name"
          :required="file.rules.includes('required') ? true : false"
          :tooltip="file.tooltip"
        >
          <validation-provider
            :rules="file.rules"
            :name="file.name"
            v-slot="{ errors }"
            :ref="file.id"
          >
            <cz-date-picker
              v-model="localForm[file.idField]"
              v-if="file.type === 'date'"
              :error-messages="errors"
              :min-date="new Date().toISOString()"
              :placeholder="$t('common.selectDate')"
              :readonly="readonly"
            />
            <v-input
              :error-messages="errors"
              :value="localForm[file.id]"
              v-if="file.type === 'file'"
            >
              <cz-dropzone
                accept="application/pdf"
                @change="onDocChange(file, $event)"
                width="100%"
                @blur="$refs[file.id].validate()"
                :readonly="readonly"
                :title="readonly ? $t('dropzone.readonlyTitle') : ''"
              >
                <div
                  slot="files"
                  v-if="localForm[file.id]"
                  class="py-2 dropzone-files overflow-hidden"
                >
                  <cz-chip
                    :text="localForm[file.id].name"
                    color="buttonSecondary"
                    circle-edges
                    :removeable="!readonly"
                    small
                    @remove="onFileRemove(file)"
                    @click="openFile(localForm[file.id])"
                  />
                </div>
              </cz-dropzone>
            </v-input>
          </validation-provider>
        </cz-form-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { CzDropzone } from '@/components';
import { CzFormField, CzChip, CzDateInput, CzDatePicker } from '@/components';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { parse, format } from 'date-fns';
export default {
  components: { CzDropzone, CzFormField, CzChip, CzDateInput, CzDatePicker },
  name: 'OnBoardingDocuments',
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isBusinessLicenseRequired: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.localForm = cloneDeep(this.value);
  },
  computed: {
    fieldGroups() {
      return [
        {
          title: this.$t('vendor.onboarding.accountingBooksCertificationTitle'),
          fields: [
            {
              file: this.localForm.accountingBooksCertificationFile,
              type: 'file',
              id: 'accountingBooksCertificationFile',
              idField: 'accountingBooksCertificationFileId',
              name: this.$t(
                'vendor.certification.accountingBooksCertification'
              ),
              tooltip: this.$t(
                'vendor.certification.accountingBooksCertificationTooltip'
              ),
              rules: 'required'
            },
            {
              value: this.localForm.accountingBooksCertificationExpirationDate,
              id: 'accountingBooksCertificationExpirationDate',
              type: 'date',
              idField: 'accountingBooksCertificationExpirationDate',
              name: this.$t(
                'vendor.certification.accountingBooksCertificationExpirationDate'
              ),
              rules: 'required|date_format:dd/MM/yyyy|date_future:dd/MM/yyyy'
            }
          ]
        },
        {
          title: this.$t('vendor.onboarding.otherDocuments'),
          fields: [
            {
              file: this.localForm.taxDeductionAuthorizationFile,
              type: 'file',
              id: 'taxDeductionAuthorizationFile',
              idField: 'taxDeductionAuthorizationFileId',
              name: this.$t('vendor.certification.taxDeductionAuthorization'),
              tooltip: this.$t(
                'vendor.certification.taxDeductionAuthorizationTooltip'
              ),
              rules: 'required'
            },
            {
              file: this.localForm.taxDeductionAuthorizationFile,
              type: 'file',
              id: 'proofOfBankAccountOwnershipFile',
              idField: 'proofOfBankAccountOwnershipFileId',
              name: this.$t('vendor.certification.proofOfBankAccountOwnership'),
              rules: 'required'
            },
            {
              file: this.localForm.taxDeductionAuthorizationFile,
              type: 'file',
              id: 'businessLicenseFile',
              idField: 'businessLicenseFileId',
              name: this.$t('vendor.certification.businessLicense'),
              rules: this.isBusinessLicenseRequired ? 'required' : ''
            }
          ]
        }
      ];
    }
  },
  data() {
    return {
      localForm: null,
      selectedAccountingBooksCertificationDoc: null
    };
  },
  methods: {
    format,
    // onDateChange(field, date) {
    //   if (date) {
    //     this.localForm[field.idField] = parse(date, 'dd/MM/yyyy', new Date());
    //   } else {
    //     this.localForm[field.idField] = '';
    //   }
    // },
    async onDocChange(item, files) {
      this.$set(this.localForm, item.id, files[0]);
      item.file = files[0];
      this.$emit('change', item);
    },
    onFileRemove(item) {
      this.localForm[item.id] = null;
      // if this file is not required
      // we can also remove the assigned file id to it
      if (!item.rules.includes('required')) {
        this.localForm[item.idField] = '';
      }
      this.$emit('remove', item);
    },
    getFileUrl(file) {
      if (file instanceof File) {
        return encodeURI(URL.createObjectURL(file));
      } else if (file?.url) {
        return file?.url;
      }

      return null;
    },
    openFile(file) {
      const url = this.getFileUrl(file);
      if (url) {
        window.open(url, '_blank');
      }
    }
  },
  watch: {
    localForm: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      }
    }
  }
};
</script>

<style></style>
