<template>
  <cz-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('admin.workflowManagement.workflowStepDialog.title')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form class="mt-2 d-flex flex-column" @submit.prevent="onSubmit">
        <cz-form-field
          :label="$t('admin.workflowManagement.workflowStepDialog.stepName')"
          required
        >
          <validation-provider
            rules="required"
            :name="$t('admin.workflowManagement.workflowStepDialog.stepName')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="form.stepTitle"
              :error-messages="errors"
              :readonly="isEditMode"
            />
          </validation-provider>
        </cz-form-field>

        <cz-form-field
          :label="
            $t('admin.workflowManagement.workflowStepDialog.approverRole')
          "
          required
        >
          <validation-provider
            rules="required"
            :name="
              $t('admin.workflowManagement.workflowStepDialog.approverRole')
            "
            v-slot="{ errors }"
          >
            <cz-autocomplete
              v-model="form.approverRole"
              :error-messages="errors"
              :items="approversRoleTypes"
              :placeholder="$t('common.selectValue')"
              :readonly="isEditMode"
            />
          </validation-provider>
        </cz-form-field>

        <cz-form-field
          v-if="form.approverRole"
          :label="`${$t(
            'admin.workflowManagement.workflowStepDialog.defaultApproverUser'
          )} (${$t('common.optional')})`"
        >
          <validation-provider
            :name="
              $t(
                'admin.workflowManagement.workflowStepDialog.defaultApproverUser'
              )
            "
            v-slot="{ errors }"
          >
            <cz-autocomplete
              v-model="form.defaultApproverUserId"
              :error-messages="errors"
              :items="accountUserRoles[form.approverRole] || []"
              :placeholder="$t('common.selectValue')"
              item-text="user.fullName"
              item-value="userId"
              @click:clear="form.defaultApproverUserId = ''"
              clearable
            />
          </validation-provider>
        </cz-form-field>
        <cz-form-field
          :label="$t('admin.workflowManagement.workflowStepDialog.stepNumber')"
          required
        >
          <validation-provider
            rules="required|min_value:1"
            :name="$t('admin.workflowManagement.workflowStepDialog.stepNumber')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="form.stepNumber"
              :error-messages="errors"
              type="number"
              readonly
            />
          </validation-provider>
        </cz-form-field>
        <cz-button
          class="align-self-center"
          type="submit"
          large
          color="info"
          :title="
            $t(
              'admin.workflowManagement.workflowStepDialog.saveStepButtonTitle'
            )
          "
          :disabled="invalid || !saveEnabled"
          :loading="loading"
          style="min-width: 200px"
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzButton,
  CzAutocomplete
} from '@/components';
import isEqual from 'lodash.isequal';
export default {
  name: 'ManageWorkflowStepDialog',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    defaultStepNumber: {
      type: Number
    },
    accountWorkflowType: {
      type: String
    },
    accountWorkflowStep: {
      type: Object
    },
    approversRoleTypes: {
      type: Array,
      default: () => []
    },
    accountUserRoles: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzButton,
    CzAutocomplete
  },
  created() {
    if (this.defaultStepNumber) {
      this.form.stepNumber = this.defaultStepNumber;
    }

    if (this.accountWorkflowStep) {
      this.form.stepNumber = this.accountWorkflowStep.stepNumber;
      this.form.stepTitle = this.accountWorkflowStep.stepTitle;
      this.form.approverRole = this.accountWorkflowStep.approverRole;
      this.form.defaultApproverUserId =
        this.accountWorkflowStep.defaultApproverUserId;
      this.form.stepId = this.accountWorkflowStep._id;
      this.formCopy = structuredClone(this.form);
    }
  },
  computed: {
    isEditMode() {
      return !!this.accountWorkflowStep;
    },
    saveEnabled() {
      return !this.formCopy || !isEqual(this.form, this.formCopy);
    }
  },
  data() {
    return {
      form: {
        stepTitle: '',
        stepNumber: undefined,
        approverRole: '',
        defaultApproverUserId: '',
        stepId: null
      },
      formCopy: null
    };
  },
  methods: {
    onSubmit() {
      this.$emit('on-submit', this.form);
    }
  }
};
</script>

<style lang="scss" scoped></style>
