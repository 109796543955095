<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-tabs v-model="tab" color="info">
      <v-tab v-for="item in tabs" :key="item.text">
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <cz-data-table
      class="mt-10"
      height="calc(100vh - 340px)"
      v-bind="ordersTable"
      :options.sync="ordersTable.options"
      :show-expand="false"
      single-expand
      :expanded.sync="ordersTable.expanded"
      :expand-icon="mdiChevronDown"
      item-key="_id"
      @item-expanded="onOrderExpanded"
      :columns="displayedColumns"
    >
      <!-- <template #top>
        <cz-button
          :title="$t('vendor.orders.newSubmission')"
          class="mb-4"
          color="primary"
          outlined
        />
      </template> -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length"></td>
      </template>
      <template #item.orderNumber="{ item }">
        <order-info-menu :order="item" color="button" />
      </template>
      <template #item.createdAt="{ item }">
        <span>{{ formatDateToDisplay(item.createdAt) }}</span>
      </template>
      <template #item.totalAmount="{ item }">
        {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
      </template>
      <template #item.amountLeft="{ item }">
        {{ formatAmountWithCurrency(item.amountLeft, item.currency) }}
      </template>
      <template #item.accountId="{ item }">
        <customer-info-menu
          v-if="item.account"
          :customer-account="item.account"
        />
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex align-center justify-end" style="gap: 10px">
          <cz-button
            v-if="item.orderDocument"
            class="font-weight-semibold textPrimary--text"
            :href="item.orderDocument.url"
            text
            :icon-src="mdiOpenInNew"
            :title="$t('customer.orders.viewOrderDocument')"
            target="_blank"
          />

          <v-divider vertical />
          <cz-button
            :icon-src="mdiPlusCircleOutline"
            :icon-size="25"
            color="buttonSecondary"
            :title="$t('vendor.orders.createOrderSubmission')"
            @click="onNewSubmission(item)"
          />
        </div>
      </template>
    </cz-data-table>
    <new-submission-document-dialog
      v-if="newSubmissionDocDialog.show"
      v-model="newSubmissionDocDialog.show"
      v-bind="newSubmissionDocDialog"
      @continue="uploadOrderSubmissionInvoice"
      @close="newSubmissionDocDialog.show = false"
    />
  </content-layout>
</template>

<script>
import { getOrders, createOrderSubmission, uploadFiles } from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { CzDataTable, CzButton } from '@/components';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import {
  mdiReceiptTextPlus,
  mdiChevronDown,
  mdiOpenInNew,
  mdiPlusCircleOutline
} from '@mdi/js';
import CustomerInfoMenu from '@/domain/customer/components/CustomerInfoMenu.vue';
import cloneDeep from 'lodash.clonedeep';
import OrderInfoMenu from '@/domain/customer/components/OrderInfoMenu.vue';
import { mapActions } from 'vuex';
import { DOCUMENT_PREDICTION_TIMEOUT } from '@/domain/shared/constants';
export default {
  name: 'VendorOrdersPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    NewSubmissionDocumentDialog: () =>
      import('@/domain/customer/components/NewOrderDialog.vue'),
    CustomerInfoMenu,
    CzDataTable,
    CzButton,
    OrderInfoMenu
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.tab = this.selectedTab || 0;
  },
  computed: {
    ...mapFields('vendor', ['vendor']),
    ...mapFields('auth', ['accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'vendor-home'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.ordersPageTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    currentTab() {
      return this.tabs[this.tab];
    },
    displayedColumns() {
      if (this.currentTab.id === 'active-orders') {
        return this.columns;
      } else {
        return this.columns.filter((item) => item.value !== 'actions');
      }
    }
  },
  data() {
    return {
      mdiReceiptTextPlus,
      mdiPlusCircleOutline,
      mdiChevronDown,
      mdiOpenInNew,
      tab: 0,
      loading: false,
      selectedOrder: null,
      ordersTable: {
        loading: false,
        options: {},
        serverItemsLength: -1,
        expanded: [],
        items: []
      },
      columns: [
        {
          text: this.$t('customer.orders.orderNumber'),
          value: 'orderNumber',
          sortable: true,
          width: 150
        },
        {
          text: this.$t('customer.orders.orderShortDesc'),
          value: 'title',
          sortable: true,
          width: 250
        },
        {
          text: this.$t('customer.orders.createdAt'),
          value: 'createdAt',
          sortable: true
        },
        // {
        //   text: this.$t('customer.orders.shortDesc'),
        //   value: 'description',
        //   sortable: false,
        //   width: 250
        // },
        {
          text: this.$t('vendor.orders.customerName'),
          value: 'accountId'
        },
        {
          text: this.$t('customer.orders.totalAmount'),
          value: 'totalAmount',
          sortable: false
        },
        {
          text: this.$t('vendor.orders.amountLeft'),
          value: 'amountLeft',
          sortable: false
        },

        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 120
        }
      ],
      tabs: [
        {
          id: 'active-orders',
          text: this.$t('customer.activeOrders'),
          excludeColumns: [],
          actions: [],
          status: 'open'
        },
        {
          id: 'closed-orders',
          text: this.$t('customer.closedOrders'),
          excludeColumns: [],
          actions: [],
          status: 'closed'
        }
      ],
      newSubmissionDocDialog: {
        show: false,
        loading: false,
        loadingTitle: '',
        title: '',
        inputLabel: '',
        uploadProgress: 0
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    ...mapActions('vendor', ['countSubmissions']),
    formatAmountWithCurrency,
    formatDateToDisplay,
    async loadData() {
      if (!this.vendor) {
        return;
      }

      try {
        // this.ordersTable.items = [];

        const { itemsPerPage, page, sortBy, sortDesc } =
          this.ordersTable.options;

        const query = {
          vendorId: this.vendor._id,
          status: this.currentTab.status,
          $populate: ['account'],
          $limit: itemsPerPage
        };

        if (sortBy?.length) {
          query.$sort = {
            [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
          };
        }

        this.ordersTable.loading = true;
        query.$skip = (page - 1) * itemsPerPage;
        const { data, total } = await getOrders(query);
        this.ordersTable.serverItemsLength = total;
        this.ordersTable.items = data;
      } finally {
        this.ordersTable.loading = false;
      }
    },
    onNewSubmission(order) {
      this.selectedOrder = cloneDeep(order);
      this.newSubmissionDocDialog.inputLabel = this.$t(
        'vendor.orders.orderInvoiceDocument',
        [order.orderNumber]
      );
      this.newSubmissionDocDialog.title = this.$t(
        'vendor.orders.newSubmissionInfo',
        [order.orderNumber]
      );
      this.newSubmissionDocDialog.show = true;
    },
    onOrderExpanded({ item, value }) {},
    async uploadOrderSubmissionInvoice(file) {
      try {
        this.newSubmissionDocDialog.loading = true;

        this.newSubmissionDocDialog.loadingTitle = this.$t(
          'vendor.orders.uploadingDocument'
        );

        const [documentFile] = await uploadFiles(
          [file],
          this.accountId,
          null,
          undefined,
          undefined,
          file.type?.startsWith('image/') ? 'image-to-pdf' : undefined // transform images to pdf
        );

        const document = await createOrderSubmission({
          relatedOrderId: this.selectedOrder._id,
          customerAccountId: this.selectedOrder.accountId,
          accountId: this.accountId,
          documentFileId: documentFile._id,
          vendorId: this.vendor._id,
          status: 'new'
        });

        this.newSubmissionDocDialog.loadingTitle = this.$t(
          'vendor.orders.processingDocument'
        );

        // after document has been created, we need to wait about 4-5 seconds to order to be processed
        // In the future it will be done via messaging queue or via websockets.
        setTimeout(() => {
          clearInterval(this.uploadDocTimer);
          this.countSubmissions();
          this.handleProcessedDocument(document._id);
          this.newSubmissionDocDialog.show = false;
        }, DOCUMENT_PREDICTION_TIMEOUT);

        this.newSubmissionDocDialog.uploadProgress +=
          100 / (DOCUMENT_PREDICTION_TIMEOUT / 500);

        this.uploadDocTimer = setInterval(() => {
          this.newSubmissionDocDialog.uploadProgress +=
            100 / (DOCUMENT_PREDICTION_TIMEOUT / 500);
        }, 500);
      } catch (error) {
        this.newSubmissionDocDialog.show = false;
        this.newSubmissionDocDialog.loading = false;
        this.showErrorToastMessage(error.message);
      }
    },
    async handleProcessedDocument(docId) {
      this.$router.push({
        name: 'vendor-submission',
        params: {
          submissionId: docId,
          predicted: true
        }
      });
    }
  },
  watch: {
    vendor: {
      // immediate: true,
      handler() {
        this.loadData();
      }
    },
    tab: {
      handler() {
        this.loadData();
      }
    },
    'ordersTable.options': {
      handler() {
        this.loadData();
      }
    }
  }
};
</script>

<style></style>
