<template>
  <div
    class="content d-flex align-center justify-center overflow-y-auto"
    style="row-gap: 20px; max-height: calc(100vh - 350px)"
  >
    <img :src="sendImage" style="max-height: 250px" />
    <div class="d-flex align-center flex-column" style="gap: 15px">
      <div class="text-h6 font-weight-semibold" v-if="!sending">
        אם הכל מוכן אז...
      </div>
      <div class="text-h5 font-weight-semibold" v-else>
        {{ $t('customer.orders.activatingOrder') }}
      </div>

      <cz-button
        class="font-weight-bold text-title"
        :title="$t('customer.orders.sendOrderButtonTitle')"
        :elevation="4"
        color="info"
        x-large
        :icon-src="mdiSendCheckOutline"
        :icon-size="22"
        @click="$emit('send')"
        :loading="sending"
        :disabled="!canSendOrder"
      />
    </div>
  </div>
</template>

<script>
import sendImage from '@/assets/illustrations/send.svg';
import { CzButton } from '@/components';
import { mdiSendCheckOutline } from '@mdi/js';
export default {
  name: 'OrderSummary',
  components: {
    CzButton
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    sending: {
      type: Boolean
    },
    canSendOrder: {
      type: Boolean
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  created() {},
  mounted() {},
  data() {
    return {
      mdiSendCheckOutline,
      sendImage
    };
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100vh - 300px);
}
</style>
