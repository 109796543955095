<template>
  <cz-dialog
    :width="500"
    v-on="$listeners"
    v-bind="$attrs"
    :title="$t('customer.vendorSubmissions.exportSubmissions')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form class="pa-4" @submit.prevent="onExport" :disabled="loading">
        <cz-form-field required :label="$t('common.dateRange')">
          <validation-provider
            rules="required|"
            :name="$t('common.dateRange')"
            v-slot="{ errors }"
          >
            <cz-new-date-picker
              v-model="form.dates"
              range
              :error-messages="errors"
              placeholder="dd/MM/yyyy - dd/MM/yyyy"
            />
          </validation-provider>
        </cz-form-field>

        <cz-button
          color="info"
          type="submit"
          large
          :title="$t('common.download')"
          :icon-src="mdiDownloadCircleOutline"
          :icon-size="22"
          :disabled="invalid"
          class="mt-6"
          :loading="loading"
          :min-width="120"
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzFormField, CzButton, CzNewDatePicker } from '@/components';
import { parse } from 'date-fns';
import { mdiDownloadCircleOutline } from '@mdi/js';
export default {
  name: 'ExportSubmissionsDialog',
  components: {
    CzDialog,
    CzFormField,
    CzButton,
    CzNewDatePicker
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  created() {
    if (this.filteredInvitationTypes?.length === 1) {
      this.form.role = this.filteredInvitationTypes[0].value;
      this.invitationRoleDisabled = false;
    }
  },
  data() {
    return {
      mdiDownloadCircleOutline,
      form: {
        dates: [],
        fromDate: null,
        toDate: null
      }
    };
  },
  methods: {
    onExport() {
      debugger;
      const fromDate = parse(this.form.dates[0], 'yyyy-MM-dd', new Date());
      const toDate = parse(this.form.dates[1], 'yyyy-MM-dd', new Date());
      this.$emit('on-export', {
        fromDate,
        toDate
      });
    }
  }
};
</script>

<style></style>
