<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-card flat outlined rounded="lg">
      <v-tabs
        v-model="filtersState.tab"
        style="border-radius: 8px"
        color="info"
      >
        <v-tab v-for="item in tabs" :key="item.text">
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-toolbar flat color="background" class="mt-2">
      <filters-tags :value="filtersState.filters" @input="onFilter" />
      <v-spacer />
      <cz-filter-button
        :count="activeFiltersCount(filtersState.filters)"
        :loading="loadingFilters"
        @click="showFiltersBar = !showFiltersBar"
      />
    </v-toolbar>
    <cz-data-table
      class="mt-2"
      height="calc(100vh - 390px)"
      v-bind="submissionsTable"
      :columns="columns"
      :options.sync="filtersState.options"
      :show-expand="currentTab.expandable"
      single-expand
      fixed-header
      item-key="_id"
    >
      <template #item.data-table-expand="{ item, expand, isExpanded }">
        <cz-button
          v-if="item.childSubmissionsIds && item.childSubmissionsIds.length"
          text
          :icon-size="28"
          :width="28"
          :height="28"
          :min-width="28"
          :ripple="true"
          :icon-src="isExpanded ? mdiChevronUp : mdiChevronDown"
          @click.stop="onItemExpand(item, expand, isExpanded)"
        />
      </template>

      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="background">
          <cz-data-table
            hide-default-footer
            :columns="childColumns"
            v-bind="childSubmissionsTable"
            @click:row="onSubmissionClicked"
            inner-table
            fixed-header
            style="max-height: 300px"
            class="ma-3"
          >
            <template #item.documentNumber="{ item }">
              <router-link
                :to="{
                  name: 'customer-submission',
                  params: {
                    submissionId: item._id
                  }
                }"
                class="font-weight-semibold text-body-1 button--text"
              >
                {{ item.documentNumber }}
              </router-link>
            </template>
            <template #item.issueDate="{ item }">
              <span>{{ formatDateToDisplay(item.issueDate) }}</span>
            </template>
            <template #item.status="{ item }">
              {{ item.statusText }}
            </template>
            <template #item.createdAt="{ item }">
              <span>{{ formatDateToDisplay(item.createdAt) }}</span>
            </template>
            <template #item.nav="{ item }">
              <cz-button
                :icon-size="24"
                text
                :icon-src="mdiChevronLeft"
                :to="{
                  name: 'customer-submission',
                  params: {
                    submissionId: item._id
                  }
                }"
                color="info"
              />
            </template>
            <template #item.updatedInFinancialSystem="{ item }">
              <div
                class="d-flex justify-center"
                v-if="item.canUpdateSubmissionToFinancialSystem"
              >
                <v-tooltip left :disabled="item.updatedInFinancialSystem">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <cz-checkbox
                        :input-value="item.updatedInFinancialSystem"
                        color="info"
                        @click.stop="
                          onUpdateInFinancialSystemChange(item, true)
                        "
                      />
                    </div>
                  </template>
                  <span>{{
                    $t('customer.orders.updatedInFinancialSystemTooltip')
                  }}</span>
                </v-tooltip>
              </div>
            </template>
          </cz-data-table>
        </td>
      </template>
      <template #item.documentNumber="{ item }">
        <router-link
          :to="{
            name: 'customer-submission',
            params: {
              submissionId: item._id
            }
          }"
          class="font-weight-semibold text-title button--text"
        >
          {{ item.documentNumber }}
        </router-link>
      </template>
      <template #item.documentStatus="{ item }">
        <cz-chip
          v-if="item.documentStatusText"
          :text="item.documentStatusText"
          small
          :color="
            item.documentStatus === SubmissionDocumentStatus.OPEN
              ? 'buttonSecondary darken-1'
              : 'primary'
          "
          dark
        />
      </template>
      <template #item.status="{ item }">
        {{ item.statusText }}
      </template>
      <template #item.issueDate="{ item }">
        <span>{{ formatDateToDisplay(item.issueDate) }}</span>
      </template>
      <template #item.createdAt="{ item }">
        <span>{{ formatDateToDisplay(item.createdAt) }}</span>
      </template>
      <template #item.totalAmount="{ item }">
        {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
      </template>
      <template #item.vatAmount="{ item }">
        {{ formatAmountWithCurrency(item.vatAmount, item.currency) }}
      </template>
      <template #item.netAmount="{ item }">
        {{ formatAmountWithCurrency(item.netAmount, item.currency) }}
      </template>
      <template #item.vendor="{ item }">
        <vendor-info-menu v-if="item.vendor" :vendor="item.vendor" />
      </template>
      <template #item.relatedOrder="{ item }">
        <order-info-menu
          v-if="item.relatedOrder"
          :order="item.relatedOrder"
          @show-order="onShowFullOrder(item)"
          show-order-link
        />
        <span v-else-if="item.source === 'orderless'">
          {{ $t('customer.orderlessSubmissions.orderlessSubmission') }}
        </span>
      </template>
      <template #item.approvalRound="{ item }">
        <cz-button
          :title="$t('customer.orders.viewApprovalRound')"
          color="primary"
          small
          @click.stop="showApprovalRoundForItem(item)"
          :icon-src="mdiCheckAll"
        />
      </template>
      <template #item.approverUserId="{ item }">
        <user-info-menu :user="item.approverUser" v-if="item.approverUser" />
      </template>

      <template #item.updatedInFinancialSystem="{ item }">
        <div
          class="d-flex justify-center"
          v-if="item.canUpdateSubmissionToFinancialSystem"
        >
          <v-tooltip left :disabled="item.updatedInFinancialSystem">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <cz-checkbox
                  :input-value="item.updatedInFinancialSystem"
                  color="info"
                  @click.stop="onUpdateInFinancialSystemChange(item)"
                />
              </div>
            </template>
            <span>{{
              $t('customer.orders.updatedInFinancialSystemTooltip')
            }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #item.nav="{ item }">
        <cz-button
          :icon-size="30"
          text
          :icon-src="mdiChevronLeft"
          :to="{
            name: 'customer-submission',
            params: {
              submissionId: item._id
            }
          }"
          color="info"
        />
      </template>
    </cz-data-table>

    <filters-bar
      v-if="filtersState.filters"
      v-model="showFiltersBar"
      @close="showFiltersBar = false"
      @on-filter="onFilter"
      :filter-fields="filtersState.filters"
    />
  </content-layout>
</template>

<script>
import {
  getOrderSubmissions,
  getUserRoles,
  updateOrderSubmission
} from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import {
  CzDataTable,
  CzButton,
  CzChip,
  CzCheckbox,
  CzFilterButton
} from '@/components';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { userHasRoleWithName } from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';
import UserInfoMenu from '@/domain/auth/components/UserInfoMenu.vue';
import {
  mdiDraw,
  mdiFileEyeOutline,
  mdiCircleOutline,
  mdiCircle,
  mdiChevronLeft,
  mdiFilterOutline,
  mdiFilterCheckOutline,
  mdiNumeric,
  mdiAccountCardOutline,
  mdiCalendarRangeOutline,
  mdiTagMultipleOutline,
  mdiAccount,
  mdiCheckAll,
  mdiChevronUp,
  mdiChevronDown
} from '@mdi/js';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import { DecisionType } from '../components/SubmissionDecisionDialog.vue';
import { typesMixin } from '@/shared/mixins';
import VendorInfoMenu from '../components/VendorInfoMenu.vue';
import OrderInfoMenu from '../components/OrderInfoMenu.vue';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import store from '@/core/store';

import {
  formatFiltersForServer,
  activeFiltersCount,
  hasActiveFilters
} from '@/shared/services/filters.service';
import SubmissionDocumentStatus from '@/shared/types/SubmissionDocumentStatus';
import FiltersTags from '@/domain/shared/components/FiltersTags.vue';
export default {
  name: 'CustomerSubmissionsPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    FiltersBar: () => import('@/domain/shared/components/FiltersBar.vue'),
    FiltersTags,
    VendorInfoMenu,
    CzFilterButton,
    OrderInfoMenu,
    CzDataTable,
    CzButton,
    CzCheckbox,
    CzChip,
    UserInfoMenu
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    }
  },
  mixins: [typesMixin],
  created() {
    if (this.filtersState.tab === -1) {
      this.filtersState.tab = this.selectedTab || 0;
    }

    if (this.filtersState.filters === null) {
      this.initializeFilters();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'customer-submission') {
      store.dispatch('orderSubmissions/resetFiltersState', {}, { root: true });
    }
    next();
  },
  computed: {
    ...mapFields('auth', ['accountId', 'currentUser', 'account']),
    ...mapFields('orderSubmissions', ['filtersState']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.submissionsPageTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    userIsAuthorizer() {
      return userHasRoleWithName(
        this.currentUser,
        RoleType.CUSTOMER_AUTHORIZER
      );
    },
    userIsPurchaser() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER);
    },
    userIsObserver() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_OBSERVER);
    },
    userIsAccountManager() {
      return userHasRoleWithName(
        this.currentUser,
        RoleType.CUSTOMER_ACCOUNT_MANAGER
      );
    },
    currentTab() {
      return this.tabs[this.filtersState.tab];
    },
    submissionStatus() {
      if (this.filtersState.tab === 0) {
        return [OrderSubmissionStatus.PENDING_APPROVAL];
      } else if (this.filtersState.tab === 1) {
        return [
          OrderSubmissionStatus.PENDING_APPROVAL,
          OrderSubmissionStatus.APPROVED,
          OrderSubmissionStatus.REJECTED,
          OrderSubmissionStatus.RETURNED
        ];
      } else {
        return null;
      }
    },
    columns() {
      const userRoles = this.currentUser?.roles.map((item) => item.role);
      return this.submissionsTable?.columns?.filter((item) => {
        if (!item.visibleToRoles && !item.visibleInTabIds) {
          return true;
        }
        if (item.visibleInTabIds) {
          if (!item.visibleInTabIds.includes(this.currentTab.id)) {
            return false;
          }
        }
        return userRoles.some((role) => item.visibleToRoles.includes(role));
      });
    },
    childColumns() {
      const columns = this.columns;
      return columns.filter((item) => !!item.isChildColumn);
    }
  },
  data() {
    return {
      mdiChevronUp,
      mdiCircleOutline,
      mdiCircle,
      mdiChevronDown,
      mdiCheckAll,
      mdiChevronLeft,
      mdiDraw,
      mdiFileEyeOutline,
      mdiFilterOutline,
      mdiFilterCheckOutline,
      loading: false,
      approvers: [],
      selectedSubmission: null,
      SubmissionDocumentStatus,
      reviewSubmissionDialog: {
        show: false,
        saving: false,
        sending: false,
        orderSubmission: null,
        readOnlyMode: true,
        decisionMode: true
      },
      showFiltersBar: false,
      loadingFilters: false,
      filtersLoaded: false,
      tabs: [
        {
          id: 'pending-submissions',
          text: this.$t('customer.vendorSubmissions.pendingSubmissionsTitle'),
          excludeColumns: [],
          actions: [],
          expandable: false
        },
        {
          id: 'handeled-submissions',
          text: this.$t('customer.vendorSubmissions.handeledSubmissionsTitle'),
          excludeColumns: [],
          actions: [],
          expandable: true
        }
      ],
      submissionsTable: {
        loading: false,
        serverItemsLength: -1,
        columns: [
          {
            text: this.$t('customer.vendorSubmissions.submissionNumber'),
            value: 'documentNumber',
            sortable: true,
            isChildColumn: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.documentStatus'),
            value: 'documentStatus',
            sortable: true,
            isChildColumn: false,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.submissionStatus'),
            value: 'status',
            sortable: true,
            isChildColumn: true,
            width: 150
          },
          {
            text: this.$t('common.documentType'),
            value: 'documentTypeText',
            isChildColumn: true,
            sortable: false,
            width: 120
          },
          {
            text: this.$t('customer.vendorSubmissions.vendorName'),
            value: 'vendor',
            sortable: false,
            isChildColumn: false
          },
          {
            text: this.$t('vendor.orderSubmissions.netAmount'),
            value: 'netAmount',
            isChildColumn: true,
            sortable: false,
            width: 150
          },
          {
            text: this.$t('vendor.orderSubmissions.vatAmount'),
            value: 'vatAmount',
            isChildColumn: true,
            sortable: false,
            width: 150
          },
          {
            text: this.$t('vendor.orderSubmissions.totalAmount'),
            value: 'totalAmount',
            isChildColumn: true,
            sortable: false,
            width: 150
          },
          {
            text: this.$t('vendor.orderSubmissions.issueDate'),
            value: 'issueDate',
            isChildColumn: true,
            sortable: true,
            width: 150
          },
          {
            text: this.$t('vendor.orderSubmissions.creationDate'),
            value: 'createdAt',
            sortable: true,
            width: 150
          },
          {
            text: this.$t('vendor.orderSubmissions.relatedOrder'),
            value: 'relatedOrder',
            sortable: false
          },
          {
            text: this.$t('customer.orders.updatedInFinancialSystem'),
            value: 'updatedInFinancialSystem',
            visibleToRoles: [RoleType.CUSTOMER_ACCOUNT_MANAGER],
            visibleInTabIds: ['handeled-submissions'],
            isChildColumn: true,
            align: 'center',
            width: 200
          },
          {
            text: '',
            value: ''
          },
          {
            text: '',
            value: 'nav',
            sortable: false,
            isChildColumn: true,
            align: 'center',
            width: 50
          }
        ],
        items: []
      },
      childSubmissionsTable: {
        items: [],
        loading: false
      }
    };
  },
  methods: {
    hasActiveFilters,
    activeFiltersCount,
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    formatDateToDisplay,
    formatAmountWithCurrency,
    async loadData(reset = false) {
      if (reset) {
        this.filtersState.options.page = 1;
      }

      try {
        this.submissionsTable.loading = true;

        // get all submissions for list of orders
        const { itemsPerPage, page, sortBy, sortDesc } =
          this.filtersState.options;

        const $skip = (page - 1) * itemsPerPage;
        // const orderIds = orders.map((item) => item._id);

        let query = {
          customerAccountId: this.accountId,
          status: { $in: this.submissionStatus },
          $populate: ['vendor'],
          $limit: itemsPerPage,
          $skip
        };

        if (this.filtersState.tab === 1) {
          query = {
            ...query,
            parentSubmissionId: { $exists: false }
          };
        }

        if (sortBy?.length) {
          query.$sort = {
            [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
          };
        }

        if (this.filtersState.filters?.length) {
          const formattedFilters = formatFiltersForServer(
            cloneDeep(this.filtersState.filters)
          );
          formattedFilters.forEach((field) => {
            query[field.fieldname] = field.value;
          });
        }

        if (!this.userIsObserver) {
          if (this.filtersState.tab === 0) {
            query.approverUserId = this.currentUser._id;
          } else if (this.filtersState.tab === 1) {
            if (!this.userIsPurchaser) {
              query.handeledByUserIds = { $in: [this.currentUser._id] };
            }
            query.approverUserId = { $ne: this.currentUser._id };
            query.approversUserIds = { $in: [this.currentUser._id] };
          }
        }

        // get all submissions for the given orders
        const { data: submissions, total: submissionsCount } =
          await getOrderSubmissions(query);
        this.submissionsTable.serverItemsLength = submissionsCount;
        this.submissionsTable.items = submissions;
      } finally {
        this.submissionsTable.loading = false;
      }
    },
    onItemExpand(item, expand, isExpanded) {
      this.childSubmissionsTable.items = [];
      this.expandedItems = [item._id];
      expand(!isExpanded);
      this.loadChildSubmissions(item._id);
    },
    async onUpdateInFinancialSystemChange(item, isChildItem = false) {
      const updatedFlat = !item.updatedInFinancialSystem;
      try {
        const _items = structuredClone(
          isChildItem
            ? this.childSubmissionsTable.items
            : this.submissionsTable.items
        );
        const index = _items.findIndex((i) => i._id === item._id);
        if (index !== -1) {
          const _item = structuredClone(item);
          _item.updatedInFinancialSystem = updatedFlat;
          _items[index] = _item;
          if (isChildItem) {
            this.childSubmissionsTable.items = _items;
          } else {
            this.submissionsTable.items = _items;
          }
        }

        await updateOrderSubmission(item._id, {
          updatedInFinancialSystem: updatedFlat
        });
      } finally {
      }
    },
    async loadChildSubmissions(parentSubmissionId) {
      if (!parentSubmissionId) {
        return;
      }
      try {
        this.childSubmissionsTable.loading = true;
        const { data } = await getOrderSubmissions({
          parentSubmissionId,
          status: { $nin: ['cancelled'] },
          customerAccountId: this.accountId
        });
        this.childSubmissionsTable.items = data;
      } finally {
        this.childSubmissionsTable.loading = false;
      }
    },
    async loadSubmissionsFilters() {
      const { data: userRoles } = await getUserRoles({
        accountId: this.accountId,
        role: {
          $in: [RoleType.CUSTOMER_AUTHORIZER, RoleType.CUSTOMER_PURCHASER]
        },
        $populate: ['user']
      });

      this.approvers = userRoles
        .filter((item) =>
          [RoleType.CUSTOMER_AUTHORIZER, RoleType.CUSTOMER_PURCHASER].includes(
            item.role
          )
        )
        .map((item) => item.user);

      this.filtersLoaded = true;
    },
    onFilter(filters) {
      this.showFiltersBar = false;
      if (!isEqual(this.filtersState.filters, filters)) {
        this.filtersState.filters = cloneDeep(filters);
        this.loadData(true);
      }
    },

    initializeFilters() {
      const initialFilters = [
        {
          fieldname: 'documentNumber',
          label: this.$t('customer.orders.filters.submissionNumber'),
          type: 'input',
          inputType: 'number',
          icon: mdiNumeric,
          value: ''
        },
        {
          fieldname: 'relatedOrderNumber',
          label: this.$t('customer.orders.filters.orderNumber'),
          type: 'input',
          inputType: 'number',
          icon: mdiNumeric,
          value: ''
        },
        {
          fieldname: 'vendorId',
          label: this.$t('customer.orders.filters.vendor'),
          type: 'vendors',
          items: [],
          icon: mdiAccountCardOutline,
          value: ''
        },
        {
          fieldname: 'issueDate',
          label: this.$t('customer.orders.filters.issueDate'),
          type: 'datepicker',
          value: '',
          placeholder: 'dd/MM/yyyy - dd/MM/yyyy',
          icon: mdiCalendarRangeOutline,
          multiple: false,
          operator: 'bt'
        },
        {
          fieldname: 'approversUserIds',
          label: this.$t('customer.orders.approvers'),
          type: 'autocomplete',
          itemText: 'fullName',
          itemValue: '_id',

          itemsHandler: () => {
            return this.approvers;
          },
          value: [],
          multiple: true,
          icon: mdiAccount,
          operator: 'in'
        },
        {
          fieldname: 'tags',
          label: this.$t('customer.orders.filters.tags'),
          type: 'tags',
          items: this.account.systemTags,
          value: [],
          icon: mdiTagMultipleOutline,
          operator: 'in'
        }
      ];

      this.filtersState.filters = cloneDeep(initialFilters);
    },

    relatedOrderMenuItems(item) {
      if (!item.relatedOrder) {
        return [];
      }

      const items = [
        {
          title: this.$t('customer.orders.orderNumber'),
          subtitle: item.relatedOrder.orderNumber
        },
        {
          title: this.$t('customer.orders.vendorId'),
          subtitle: item.relatedOrder.vendorId
        },
        {
          title: this.$t('customer.orders.issueDate'),
          subtitle: formatDateToDisplay(item.relatedOrder.issueDate)
        },
        {
          title: this.$t('customer.orders.totalAmount'),
          subtitle: formatAmountWithCurrency(item.relatedOrder.totalAmount)
        }
      ];

      if (item.relatedOrder.description) {
        items.push({
          title: this.$t('customer.orders.shortDesc'),
          subtitle: item.relatedOrder.description
        });
      }

      items.push({
        title: this.$t('customer.orders.orderDocument'),
        subtitle: this.$t('customer.orders.viewOrderDocument'),
        handler: () => {
          window.open(item.relatedOrder.orderDocument.url, '_blank');
        }
      });

      return items;
    },
    onHandleSubmission(item) {
      this.reviewSubmissionDialog.orderSubmission = cloneDeep(item);
      // this.reviewSubmissionDialog.readOnlyMode = !this.canEditSubmission(item);
      this.reviewSubmissionDialog.decisionMode = this.canEditSubmission(item);
      this.reviewSubmissionDialog.show = true;
    },
    onSubmissionClicked(item) {
      this.$router.push({
        name: 'customer-submission',
        params: {
          submissionId: item._id
        }
      });
    },
    /**
     * Authorizer can edit submission only if submission status is pending autorizer
     * Purchaser can edit submission only when submission status is pedning purchaser
     * In all other statuses, submission cannot be editable by the customer
     */
    canEditSubmission(item) {
      return (
        (this.userIsAuthorizer &&
          item.status === OrderSubmissionStatus.PENDING_AUTHORIZER) ||
        (this.userIsPurchaser &&
          item.status === OrderSubmissionStatus.PENDING_PURCHASER)
      );
    },
    onSubmissionDecision(decisionType) {
      // remove order submission from list
      const index = this.submissionsTable.items.findIndex(
        (item) => item._id === this.reviewSubmissionDialog.orderSubmission._id
      );
      if (index !== -1) {
        this.submissionsTable.items.splice(index, 1);
      }
      this.reviewSubmissionDialog.show = false;
      switch (decisionType) {
        case DecisionType.APPROVE:
          this.showSuccessToastMessage(
            this.$t('customer.vendorSubmissions.submissionApprovedSuccess')
          );
          break;
        case DecisionType.REJECT:
          this.showSuccessToastMessage(
            this.$t('customer.vendorSubmissions.submissionRejectedSuccess')
          );
          break;
        case DecisionType.RETURN:
          this.showSuccessToastMessage(
            this.$t('customer.vendorSubmissions.submissionReturnedSuccess')
          );
          break;
      }
    },
    showApprovalRoundForItem() {},
    onShowFullOrder(submission) {
      const { href } = this.$router.resolve({
        name: 'customer-order',
        params: {
          id: submission?.relatedOrderId
        }
      });
      window.open(href, '_blank');
    }
  },
  watch: {
    'filtersState.tab': {
      handler() {
        this.loadData(true);
      }
    },
    'filtersState.options': {
      handler() {
        this.loadData();
      }
    },
    showFiltersBar: {
      handler(value) {
        if (value && !this.filtersLoaded) {
          this.loadSubmissionsFilters();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
