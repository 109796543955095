<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="1300"
    :max-width="'90%'"
    :title="$t('customer.vendorSubmissions.submissionHistory')"
    scrollable
  >
    <v-timeline reverse :dense="$vuetify.breakpoint.smAndDown" class="pa-10">
      <template v-for="(item, index) in items">
        <v-timeline-item
          v-if="item.approved || item.status === 'open'"
          :key="`${item._id}_approved`"
          color="positive"
          :icon="mdiCheck"
          icon-color="white"
          large
        >
          <v-card color="white">
            <v-card-text class="d-flex flex-column">
              <div class="d-flex align-center" style="column-gap: 3px">
                <div
                  class="text-title textPrimary--text"
                  v-html="
                    $t('customer.vendorSubmissions.approvedBy', [
                      item.user.fullName
                    ])
                  "
                />

                <cz-menu-button
                  v-if="item.note"
                  :button-icon-size="22"
                  :menu-max-width="250"
                  flat
                  color="primary"
                  :button-icon-src="mdiInformationOutline"
                  :menuItems="getStatusMenuItems(item)"
                />
              </div>
              <div class="text-body-1">
                {{ formatDateTimeToDisplay(item.updatedAt) }}
              </div>
              <div
                class="text-subtitle-2 textPrimary--text"
                v-if="index > 0"
                v-html="
                  $t('customer.vendorSubmissions.submissionHistoryCreatedBy', [
                    items[index - 1].approverUser
                      ? items[index - 1].approverUser.fullName
                      : items[index - 1].user.fullName
                  ])
                "
              ></div>
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item
          :key="item._id"
          :color="getStatusColor(item.status)"
          :icon="getStatusIcon(item.status)"
          icon-color="white"
          large
        >
          <v-card color="white">
            <v-card-text class="d-flex flex-column" style="row-gap: 10px">
              <div class="d-flex align-center" style="column-gap: 3px">
                <div class="font-weight-regular text-title textPrimary--text">
                  {{ item.statusText }}
                </div>
                <cz-menu-button
                  v-if="
                    item.status === OrderSubmissionStatus.RETURNED ||
                    item.status === OrderSubmissionStatus.REJECTED
                  "
                  :button-icon-size="22"
                  :menu-max-width="250"
                  flat
                  color="primary"
                  :button-icon-src="mdiInformationOutline"
                  :menuItems="getStatusMenuItems(item)"
                />
              </div>

              <div class="text-body-1">
                {{ formatDateTimeToDisplay(item.updatedAt) }}
              </div>
              <div
                class="text-subtitle-2 textPrimary--text"
                v-html="
                  $t('customer.vendorSubmissions.submissionHistoryCreatedBy', [
                    item.approverUser
                      ? item.approverUser.fullName
                      : item.user.fullName
                  ])
                "
              />
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </template>
    </v-timeline>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzSpinner, CzMenuButton } from '@/components';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import {
  mdiFileDocumentPlusOutline,
  mdiFileDocumentCheckOutline,
  mdiFileDocumentArrowRightOutline,
  mdiFileDocumentAlertOutline,
  mdiAccountClockOutline,
  mdiInformation,
  mdiCheckOutline,
  mdiCheck,
  mdiCheckAll,
  mdiInformationOutline
} from '@mdi/js';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import OrderStatus from '@/shared/types/OrderStatus';
export default {
  name: 'OrderApprovalHistoryDialog',
  components: {
    CzDialog,
    CzSpinner,
    CzMenuButton
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiFileDocumentAlertOutline,
      mdiCheckOutline,
      mdiCheck,
      mdiFileDocumentCheckOutline,
      mdiInformation,
      mdiInformationOutline,
      OrderSubmissionStatus,
      mdiCheckAll
    };
  },
  methods: {
    formatDateTimeToDisplay,
    getStatusColor(status) {
      switch (status) {
        case OrderStatus.APPROVED:
        case OrderStatus.OPEN:
          return 'positive';
        case OrderStatus.REJECTED:
          return 'negative';
        case OrderStatus.NEW:
        case OrderStatus.NEW_PREDICTED:
          return 'primary darken-1';
        case OrderStatus.RETURNED:
          return 'primary darken-1';
        case OrderStatus.PENDING_APPROVAL:
          return 'primary darken-1';
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case OrderStatus.APPROVED:
        case OrderStatus.OPEN:
          return mdiCheckAll;
        case OrderStatus.REJECTED:
          return mdiFileDocumentAlertOutline;
        case OrderStatus.NEW:
        case OrderStatus.NEW_PREDICTED:
          return mdiFileDocumentPlusOutline;
        case OrderStatus.RETURNED:
          return mdiFileDocumentArrowRightOutline;
        case OrderStatus.PENDING_APPROVAL:
          return mdiAccountClockOutline;
      }
    },
    getStatusMenuItems(item) {
      switch (item.status) {
        case OrderStatus.RETURNED:
          return [
            {
              id: 'return-type',
              title: this.$t('customer.vendorSubmissions.returnType'),
              subtitle: item.returnTypeText
            },
            {
              id: 'note',
              title: this.$t('common.note'),
              subtitle: item.note
            }
          ];
        case OrderStatus.REJECTED:
          return [
            {
              id: 'return-type',
              title: this.$t('customer.vendorSubmissions.rejectType'),
              subtitle: item.rejectTypeText
            },
            {
              id: 'note',
              title: this.$t('common.note'),
              subtitle: item.note
            }
          ];
        case OrderStatus.APPROVED:
        case OrderStatus.OPEN:
          if (item.note) {
            return [
              {
                id: 'note',
                title: this.$t('common.note'),
                subtitle: item.note
              }
            ];
          } else {
            return [];
          }
        default:
          if (item.note) {
            return [
              {
                id: 'note',
                title: this.$t('common.note'),
                subtitle: item.note
              }
            ];
          } else {
            return [];
          }
      }
    }
  }
};
</script>

<style></style>
