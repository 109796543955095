import { render, staticRenderFns } from "./VendorOnBoarding.vue?vue&type=template&id=22d5a7af&scoped=true"
import script from "./VendorOnBoarding.vue?vue&type=script&lang=js"
export * from "./VendorOnBoarding.vue?vue&type=script&lang=js"
import style0 from "./VendorOnBoarding.vue?vue&type=style&index=0&id=22d5a7af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d5a7af",
  null
  
)

export default component.exports