<template>
  <v-badge
    :value="hasActiveFilters"
    left
    color="buttonSecondary"
    :offset-y="14"
    :offset-x="20"
    :content="filtersCount"
  >
    <cz-button
      v-bind="$attrs"
      v-on="$listeners"
      :title="$t('common.filters')"
      color="info"
      text
      :icon-src="hasActiveFilters ? mdiFilterCheckOutline : mdiFilterOutline"
    />
  </v-badge>
</template>

<script>
import { mdiFilterCheckOutline, mdiFilterOutline } from '@mdi/js';
import { CzButton } from '@/components';
export default {
  name: 'FilterButton',
  components: {
    CzButton
  },
  props: {
    hasActiveFilters: {
      type: Boolean
    },
    filtersCount: {
      type: Number
    }
  },
  data() {
    return {
      mdiFilterCheckOutline,
      mdiFilterOutline
    };
  }
};
</script>

<style lang="scss" scoped></style>
