var render = function render(){var _vm=this,_c=_vm._self._c;return _c('content-layout',{attrs:{"breadcrumbs-items":_vm.breadcrumbsItems}},[_c('cz-data-table',_vm._b({staticClass:"mt-2",attrs:{"height":"calc(100vh - 240px)","options":_vm.filtersState.options,"fixed-header":"","item-key":"_id"},on:{"update:options":function($event){return _vm.$set(_vm.filtersState, "options", $event)}},scopedSlots:_vm._u([{key:"item.documentNumber",fn:function({ item }){return [_c('router-link',{staticClass:"font-weight-semibold text-title button--text",attrs:{"to":{
          name: 'customer-submission',
          params: {
            submissionId: item._id,
            type: 'orderless'
          }
        }}},[_vm._v(" "+_vm._s(item.documentNumber)+" ")])]}},{key:"item.documentStatusText",fn:function({ item }){return [(item.documentStatusText)?_c('cz-chip',{attrs:{"text":item.documentStatusText,"small":"","color":item.documentStatusText === _vm.SubmissionDocumentStatus.OPEN
            ? 'buttonSecondary darken-1'
            : 'primary',"dark":""}}):_vm._e()]}},{key:"item.issueDate",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDateToDisplay(item.issueDate)))])]}},{key:"item.createdAt",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDateToDisplay(item.createdAt)))])]}},{key:"item.totalAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency(item.totalAmount, item.currency))+" ")]}},{key:"item.vatAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency(item.vatAmount, item.currency))+" ")]}},{key:"item.netAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency(item.netAmount, item.currency))+" ")]}},{key:"item.approvalRound",fn:function({ item }){return [_c('cz-button',{attrs:{"title":_vm.$t('customer.orders.viewApprovalRound'),"color":"primary","small":"","icon-src":_vm.mdiCheckAll},on:{"click":function($event){$event.stopPropagation();return _vm.showApprovalRoundForItem(item)}}})]}},{key:"item.approverUserId",fn:function({ item }){return [(item.approverUser)?_c('user-info-menu',{attrs:{"user":item.approverUser}}):_vm._e()]}},{key:"item.nav",fn:function({ item }){return [_c('cz-button',{attrs:{"icon-size":30,"text":"","icon-src":_vm.mdiChevronLeft,"to":{
          name: 'customer-submission',
          params: {
            submissionId: item._id,
            type: 'orderless'
          }
        },"color":"info"}})]}}])},'cz-data-table',_vm.submissionsTable,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }