<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="mb-2 d-flex justify-space-between align-center">
      <span>{{ $t('customer.orders.orderDocument') }}</span>
      <cz-button
        v-if="order.orderDocument"
        :icon-size="22"
        color="info"
        :icon-src="mdiDownload"
        :title="$t('common.download')"
        text
        @click="download"
      />
    </v-card-title>

    <v-card-text class="pa-0">
      <div style="overflow: auto">
        <pdf-viewer
          v-if="orderDocumentUrl && !documentGenerateIsEnabled"
          :source="orderDocumentUrl"
          class="doc"
        />

        <order-document-builder
          v-else-if="generating"
          :order="order"
          :selected-vendor="selectedVendor"
          :logo-uri="logoUri"
        />

        <pdf-viewer v-else-if="pdfSrc" :source="pdfSrc" text-layer></pdf-viewer>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PdfViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
import documentImage from '@/assets/illustrations/doc.svg';
import { mdiDownload } from '@mdi/js';
import { CzButton } from '@/components';
import { saveAs } from 'file-saver';
import OrderDocumentBuilder from './OrderDocumentBuilder.vue';
import { debounce } from 'vue-debounce';
import {
  fileReaderPromise,
  ReaderType
} from '@/shared/services/fileReader.service';
import { generatePdfFromElement } from '@/shared/services/pdf.service';
import OrderStatus from '@/shared/types/OrderStatus';
export default {
  name: 'OrderDocument',
  components: {
    PdfViewer,
    CzButton,
    OrderDocumentBuilder
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    selectedVendor: {
      type: Object
    },
    maxWidth: {
      type: [String, Number],
      default: 750
    },
    async: {
      type: Boolean
    }
  },

  async mounted() {
    if (!this.order.orderDocument || this.documentGenerateIsEnabled) {
      await this.downloadLogo();
      setTimeout(() => {
        this.generatePdf();
      }, 0);
    }
  },
  computed: {
    orderDocumentUrl() {
      return this.order?.orderDocument?.url;
    },

    documentGenerateIsEnabled() {
      return (
        !this.orderDocumentUrl ||
        (this.isManualCreationMode &&
          this.order?.status === OrderStatus.RETURNED)
      );
    },
    isManualCreationMode() {
      return this.order?.creationType === 'manual';
    }
  },
  data() {
    return {
      documentImage,
      mdiDownload,
      pdfSrc: null,
      logoUri: null,
      generating: false
    };
  },
  methods: {
    download() {
      if (!this.order?.orderDocument?.url) {
        return;
      }

      saveAs(this.order.orderDocument.url, this.order.orderDocument.name);
    },
    async generatePdf() {
      return new Promise((resolve, reject) => {
        this.generating = true;
        let element = document.getElementById('order-document');

        if (!element) {
          this.$nextTick(async () => {
            element = document.getElementById('order-document');
            console.log('generate document', element);
            generatePdfFromElement(element).then((pdfSrc) => {
              // console.log('pdf generated', pdfSrc);
              this.pdfSrc = pdfSrc;
              this.$emit('change', this.pdfSrc);
              this.generating = false;
              resolve();
            });
          });
        } else {
          generatePdfFromElement(element).then((pdfSrc) => {
            this.pdfSrc = pdfSrc;
            this.$emit('change', this.pdfSrc);
            this.generating = false;
            resolve();
          });
        }
      });
    },
    async downloadLogo() {
      if (!this.order?.account?.logo) {
        return;
      }

      const data = await fetch(this.order.account.logo.url);
      const blob = await data.blob();
      this.logoUri = await fileReaderPromise(blob, ReaderType.URL);
    }
  },

  watch: {
    order: {
      immediate: false,
      deep: true,
      handler(value) {
        if (this.pdfSrc) {
          if (this.debounce) {
            this.debounce.cancel();
          }
          this.debounce = debounce(
            () =>
              setTimeout(() => {
                this.generatePdf();
              }, 0),
            1000
          );
          this.debounce(value);
        }
      }
    },
    generating: {
      handler(value) {
        // this.$emit('generating', value);
      }
    }
  }
};
</script>

<style></style>
