<template>
  <v-radio
    v-bind="$attrs"
    v-on="$listeners"
    :off-icon="mdiRadioboxBlank"
    :on-icon="mdiRadioboxMarked"
  />
</template>

<script>
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js';
export default {
  name: 'CzRadio',
  data() {
    return {
      mdiRadioboxBlank,
      mdiRadioboxMarked
    };
  }
};
</script>

<style lang="scss" scoped></style>
