<template>
  <cz-dialog
    :width="500"
    max-width="90%"
    :title="$t('vendor.updateDocsDialog.title')"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading"
  >
    <validation-observer tag="div" ref="observer" v-slot="{ invalid }">
      <v-form
        class="py-4 px-6 d-flex flex-column align-center"
        style="row-gap: 10px"
        :disabled="loading"
        @submit.prevent="$emit('submit', form)"
      >
        <cz-form-field
          :label="$t('vendor.certification.accountingBooksCertification')"
          required
          class="full-width"
        >
          <validation-provider
            :rules="'required'"
            :name="$t('vendor.certification.accountingBooksCertification')"
            v-slot="{ errors }"
            ref="accountingBooksCertification"
          >
            <v-input
              :error-messages="errors"
              :value="form.accountingBooksCertification"
            >
              <cz-dropzone
                accept="application/pdf"
                @change="onDocChange('accountingBooksCertification', $event)"
                width="100%"
                @blur="$refs.accountingBooksCertification.validate()"
                :disabled="loading"
              >
                <div slot="files" class="py-2 dropzone-files">
                  <cz-chip
                    v-if="form.accountingBooksCertification"
                    :text="form.accountingBooksCertification.name"
                    color="buttonSecondary"
                    circle-edges
                    removeable
                    small
                    @remove="form.accountingBooksCertification = null"
                    @click="viewDocument(form.accountingBooksCertification)"
                    class="file-chip"
                  />
                </div>
              </cz-dropzone>
            </v-input>
          </validation-provider>
        </cz-form-field>
        <cz-form-field
          required
          :label="
            $t(
              'vendor.certification.accountingBooksCertificationExpirationDate'
            )
          "
          class="full-width"
        >
          <validation-provider
            rules="required|date_format:dd/MM/yyyy|date_future:dd/MM/yyyy"
            :name="
              $t(
                'vendor.certification.accountingBooksCertificationExpirationDate'
              )
            "
            v-slot="{ errors }"
          >
            <cz-date-picker
              v-model="form.taxDeductionAuthorizationExpDate"
              :error-messages="errors"
              :min-date="new Date().toISOString()"
              :placeholder="$t('common.selectDate')"
              open-menu-on-click
            />
          </validation-provider>
        </cz-form-field>
        <cz-form-field
          :label="$t('vendor.certification.taxDeductionAuthorization')"
          required
          class="full-width"
        >
          <validation-provider
            :rules="'required'"
            :name="$t('vendor.certification.taxDeductionAuthorization')"
            v-slot="{ errors }"
            ref="taxDeductionAuthorization"
          >
            <v-input
              :error-messages="errors"
              :value="form.taxDeductionAuthorization"
            >
              <cz-dropzone
                accept="application/pdf"
                @change="onDocChange('taxDeductionAuthorization', $event)"
                width="100%"
                @blur="$refs.taxDeductionAuthorization.validate()"
                :disabled="loading"
              >
                <div slot="files" class="py-2 dropzone-files">
                  <cz-chip
                    v-if="form.taxDeductionAuthorization"
                    :text="form.taxDeductionAuthorization.name"
                    color="buttonSecondary"
                    circle-edges
                    removeable
                    small
                    @remove="form.taxDeductionAuthorization = null"
                    @click="viewDocument(form.taxDeductionAuthorization)"
                    class="file-chip"
                  />
                </div>
              </cz-dropzone>
            </v-input>
          </validation-provider>
        </cz-form-field>
        <a
          class="text-body-1 font-weight-semibold"
          href="https://taxinfo.taxes.gov.il/gmishurim/firstPage.aspx"
          target="_blank"
          >{{ $t('vendor.updateDocsDialog.downloadInfo') }}</a
        >
        <cz-button
          class="mt-3"
          :title="$t('vendor.updateDocsDialog.uploadButtonTitle')"
          color="info"
          large
          :disabled="invalid"
          type="submit"
          :loading="loading"
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzChip,
  CzInput,
  CzFormField,
  CzDropzone,
  CzDatePicker,
  CzButton
} from '@/components';
export default {
  name: 'VendorDocsUpdateDialog',
  components: {
    CzDialog,
    CzInput,
    CzFormField,
    CzDropzone,
    CzChip,
    CzDatePicker,
    CzButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        accountingBooksCertification: null,
        taxDeductionAuthorization: null,
        taxDeductionAuthorizationExpDate: null
      }
    };
  },
  methods: {
    onDocChange(field, files) {
      this.form[field] = files[0];
    },
    async viewDocument(file) {
      const url = encodeURI(URL.createObjectURL(file));
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.file-chip {
  cursor: pointer !important;
}
</style>
