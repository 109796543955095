<template>
  <cz-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :width="750"
    max-width="90%"
    inset
    show-actions
  >
    <ul>
      <v-slide-x-transition class="py-0" group tag="v-list">
        <li
          class="d-flex"
          style="gap: 1rem"
          v-for="(item, index) of conditions"
          :key="`condition_${index}`"
        >
          <cz-autocomplete
            v-model="item.fieldName"
            dense
            hide-details
            :items="fieldItems"
            :label="$t('admin.accountWorkflowDefaults.selectField')"
            style="max-width: 200px"
          />
          <cz-autocomplete
            v-model="item.operartor"
            :label="$t('admin.accountWorkflowDefaults.operator')"
            :items="operatorItems"
            dense
            hide-details
            style="max-width: 225px"
          >
            <template #selection="{ item }">
              <div class="d-flex align-center" style="gap: 0.5rem">
                <cz-icon :src="item.icon" v-if="item.icon" />
                <span class="text-subtitle-2">{{ item.text }}</span>
              </div>
            </template>
            <template #item="{ item }">
              <div class="d-flex align-center" style="gap: 0.5rem">
                <cz-icon :src="item.icon" v-if="item.icon" />
                <span class="text-subtitle-2">{{ item.text }}</span>
              </div>
            </template>
          </cz-autocomplete>

          <v-slide-y-transition>
            <div
              class="d-flex flex-grow-1 align-center"
              style="gap: 0.25rem"
              v-if="item.operartor"
            >
              <cz-input
                v-model="item.value1"
                :label="
                  item.operartor === 'bt'
                    ? $t('admin.accountWorkflowDefaults.valueFrom')
                    : $t('admin.accountWorkflowDefaults.value')
                "
                dense
                type="number"
                hide-details
                style="max-width: 150px"
              />
              <template v-if="item.operartor === 'bt'">
                <cz-icon :src="mdiArrowLeft" />
                <cz-input
                  v-model="item.value2"
                  v-if="item.operartor === 'bt'"
                  :label="$t('admin.accountWorkflowDefaults.valueTo')"
                  dense
                  hide-details
                  type="number"
                  style="max-width: 150px"
                />
              </template>
            </div>
          </v-slide-y-transition>
          <cz-button
            text
            :icon-src="mdiCloseCircleOutline"
            color="negative"
            tooltip="מחיקה"
            @click="onRemoveCondition(item)"
          />
        </li>
      </v-slide-x-transition>
    </ul>
    <cz-button
      :title="$t('admin.accountWorkflowDefaults.addCondition')"
      text
      color="info"
      :icon-src="mdiPlusCircleOutline"
      @click="onAddCondition"
      class="mt-4"
    />

    <template #actions v-if="conditions.length">
      <div class="d-flex align-center justify-end full-width" style="gap: 1rem">
        <cz-button
          :title="$t('common.cancel')"
          text
          color="info"
          class="px-6"
          @click="$emit('close')"
        />
        <cz-button color="info" :title="$t('common.save')" class="px-6" />
      </div>
    </template>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzButton,
  CzAutocomplete,
  CzInput,
  CzIcon
} from '@/components';
import {
  mdiPlusCircleOutline,
  mdiGreaterThan,
  mdiArrowLeftRight,
  mdiCloseCircleOutline,
  mdiEqual,
  mdiArrowLeft,
  mdiLessThanOrEqual,
  mdiGreaterThanOrEqual,
  mdiLessThan
} from '@mdi/js';
export default {
  name: 'AccountWorkflowDefaultsDialog',
  components: {
    CzDialog,
    CzInput,
    CzAutocomplete,
    CzButton,
    CzIcon
  },
  props: {
    accountWorkflow: {
      type: Object
    }
  },
  computed: {
    fieldItems() {
      return [
        {
          text: 'סכום כולל',
          value: 'totalAmount'
        }
      ];
    },
    operatorItems() {
      return [
        {
          text: this.$t('admin.accountWorkflowDefaults.operators.equal'),
          value: 'eq',
          icon: mdiEqual
        },
        {
          text: this.$t('admin.accountWorkflowDefaults.operators.greaterThan'),
          value: 'gt',
          icon: mdiGreaterThan
        },
        {
          text: this.$t(
            'admin.accountWorkflowDefaults.operators.greaterThanOrEqual'
          ),
          value: 'gte',
          icon: mdiGreaterThanOrEqual
        },

        {
          text: this.$t('admin.accountWorkflowDefaults.operators.lessThan'),
          value: 'lt',
          icon: mdiLessThan
        },
        {
          text: this.$t(
            'admin.accountWorkflowDefaults.operators.lessThanOrEqual'
          ),
          value: 'lte',
          icon: mdiLessThanOrEqual
        },
        {
          text: this.$t('admin.accountWorkflowDefaults.operators.between'),
          value: 'bt',
          icon: mdiArrowLeftRight
        }
      ];
    }
  },
  data() {
    return {
      mdiPlusCircleOutline,
      mdiCloseCircleOutline,
      mdiArrowLeft,
      conditions: []
    };
  },
  methods: {
    onAddCondition() {
      this.conditions.push({
        fieldName: '',
        operator: '',
        value1: undefined,
        value2: undefined
      });
    },
    onRemoveCondition(item) {
      this.conditions.splice(this.conditions.indexOf(item), 1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
