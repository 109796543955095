var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cz-dialog',_vm._g(_vm._b({attrs:{"title":_vm.$t('admin.workflowManagement.workflowStepDialog.title')}},'cz-dialog',_vm.$attrs,false),_vm.$listeners),[_c('validation-observer',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-form',{staticClass:"mt-2 d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('cz-form-field',{attrs:{"label":_vm.$t('admin.workflowManagement.workflowStepDialog.stepName'),"required":""}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('admin.workflowManagement.workflowStepDialog.stepName')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-input',{attrs:{"error-messages":errors,"readonly":_vm.isEditMode},model:{value:(_vm.form.stepTitle),callback:function ($$v) {_vm.$set(_vm.form, "stepTitle", $$v)},expression:"form.stepTitle"}})]}}],null,true)})],1),_c('cz-form-field',{attrs:{"label":_vm.$t('admin.workflowManagement.workflowStepDialog.approverRole'),"required":""}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('admin.workflowManagement.workflowStepDialog.approverRole')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-autocomplete',{attrs:{"error-messages":errors,"items":_vm.approversRoleTypes,"placeholder":_vm.$t('common.selectValue'),"readonly":_vm.isEditMode},model:{value:(_vm.form.approverRole),callback:function ($$v) {_vm.$set(_vm.form, "approverRole", $$v)},expression:"form.approverRole"}})]}}],null,true)})],1),(_vm.form.approverRole)?_c('cz-form-field',{attrs:{"label":`${_vm.$t(
          'admin.workflowManagement.workflowStepDialog.defaultApproverUser'
        )} (${_vm.$t('common.optional')})`}},[_c('validation-provider',{attrs:{"name":_vm.$t(
              'admin.workflowManagement.workflowStepDialog.defaultApproverUser'
            )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-autocomplete',{attrs:{"error-messages":errors,"items":_vm.accountUserRoles[_vm.form.approverRole] || [],"placeholder":_vm.$t('common.selectValue'),"item-text":"user.fullName","item-value":"userId","clearable":""},on:{"click:clear":function($event){_vm.form.defaultApproverUserId = ''}},model:{value:(_vm.form.defaultApproverUserId),callback:function ($$v) {_vm.$set(_vm.form, "defaultApproverUserId", $$v)},expression:"form.defaultApproverUserId"}})]}}],null,true)})],1):_vm._e(),_c('cz-form-field',{attrs:{"label":_vm.$t('admin.workflowManagement.workflowStepDialog.stepNumber'),"required":""}},[_c('validation-provider',{attrs:{"rules":"required|min_value:1","name":_vm.$t('admin.workflowManagement.workflowStepDialog.stepNumber')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('cz-input',{attrs:{"error-messages":errors,"type":"number","readonly":""},model:{value:(_vm.form.stepNumber),callback:function ($$v) {_vm.$set(_vm.form, "stepNumber", $$v)},expression:"form.stepNumber"}})]}}],null,true)})],1),_c('cz-button',{staticClass:"align-self-center",staticStyle:{"min-width":"200px"},attrs:{"type":"submit","large":"","color":"info","title":_vm.$t(
            'admin.workflowManagement.workflowStepDialog.saveStepButtonTitle'
          ),"disabled":invalid || !_vm.saveEnabled,"loading":_vm.loading}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }