var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"overflow-y":"auto"},attrs:{"rounded":"lg","outlined":!_vm.flat,"flat":_vm.flat}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',_vm._g(_vm._b({style:({
        opacity: _vm.loading ? 0.5 : 1
      }),attrs:{"no-data-text":_vm.noDataText || _vm.$t('common.noData'),"loading-text":_vm.$t('common.loading'),"headers":_vm.styledColumns,"header-props":{
        sortIcon: _vm.mdiArrowDown
      },"height":_vm.height,"loading":_vm.loading,"expand-icon":_vm.mdiChevronDown,"fixed-header":"","footer-props":{
        itemsPerPageOptions: [2, 5, 10, 15, 25, 50],
        nextIcon: _vm.mdiChevronRight,
        prevIcon: _vm.mdiChevronLeft,
        lastIcon: _vm.mdiChevronDoubleLeft,
        firstIcon: _vm.mdiChevronDoubleRight,
        itemsPerPageText: _vm.$t('common.itemsPerPage'),
        pageText: _vm.$t('common.pageText')
      },"items-per-page":25},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_vm._l((Object.keys(_vm.$slots)),function(slot){return _vm._t(slot,null,{"slot":slot})})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }