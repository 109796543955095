<template>
  <v-menu
    v-model="menuVisible"
    v-on="$listeners"
    v-bind="$attrs"
    offset-y
    :close-on-content-click="closeOnContentClick || clickable"
  >
    <template v-slot:activator="{ on: onMenu, attrs }">
      <cz-button
        v-if="!tooltip"
        v-on="onMenu"
        v-bind="attrs"
        :title="buttonTitle"
        :color="color"
        :icon-src="buttonIconSrc"
        :icon-size="buttonIconSize"
        :small="buttonSmall"
        :large="buttonLarge"
        :max-width="maxWidth"
        :text="flat"
        :outlined="outlined"
        :icon-color="buttonIconColor"
        :disabled="disabled"
        :hover-effect="false"
        :no-paddings="!withPaddings"
      />

      <v-tooltip top v-else>
        <template #activator="{ on: onTooltip }">
          <cz-button
            v-on="{ ...onMenu, ...onTooltip }"
            v-bind="attrs"
            :title="buttonTitle"
            :color="color"
            :icon-src="buttonIconSrc"
            :icon-size="buttonIconSize"
            :small="buttonSmall"
            :large="buttonLarge"
            :max-width="maxWidth"
            :text="flat"
            :icon-color="buttonIconColor"
            :disabled="disabled"
            :hover-effect="false"
            no-paddings
          />
        </template>

        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <v-card :min-width="menuMinWidth" :max-width="menuMaxWidth">
      <template v-if="title">
        <v-card-title class="text-body-1 font-weight-semibold pa-3">
          {{ title }}
        </v-card-title>
        <v-divider />
      </template>

      <v-card-text v-if="text" v-html="text"
       /></v-card-text
      >
      <v-list dense v-else-if="clickable">
        <v-list-item-group v-model="selection">
          <template v-for="(item, index) in menuItems">
            <v-divider v-if="item.divider" :key="`menu_item_${index}`" />
            <v-list-item
              v-else
              :key="`menu_item_${index}`"
              class="d-flex align-center"
              @click="clickable ? onActionClicked(item) : null"
            >
              <v-list-item-icon v-if="item.icon" class="pa-0 mx-2 mt-3"
                ><cz-icon :size="20" :src="item.icon" color="info"
              /></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="textPrimary--text text-subtitle-2 font-weight-semibold"
                  >{{ item.title }}</v-list-item-title
                >
                <v-list-item-subtitle
                  class="text-subtitle-2 font-weight-regular text-pre-wrap"
                  v-if="item.subtitle"
                >
                  <span v-if="!item.handler">{{ item.subtitle }}</span>
                  <a
                    v-else
                    href="javascript:void(0)"
                    style="cursor: pointer"
                    @click.stop="item.handler()"
                  >
                    {{ item.subtitle }}</a
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <v-list dense :min-width="menuMinWidth" v-else>
        <template v-for="(item, index) in menuItems">
          <v-divider v-if="item.divider" :key="`menu_item_${index}`" />
          <v-list-item
            v-else
            :key="`menu_item_${index}`"
            class="d-flex align-center"
          >
            <v-list-item-icon v-if="item.icon" class="pa-0 mx-2 mt-3"
              ><cz-icon :size="20" :src="item.icon" color="info"
            /></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="info--text text-subtitle-1 font-weight-semibold mb-2"
                >{{ item.title }}</v-list-item-title
              >
              <v-list-item-subtitle
                class="text-subtitle-2 font-weight-regular text-pre-wrap"
                v-if="item.subtitle"
              >
                <span v-if="!item.handler">{{ item.subtitle }}</span>
                <a
                  v-else
                  href="javascript:void(0)"
                  style="cursor: pointer"
                  @click.stop="item.handler"
                >
                  {{ item.subtitle }}</a
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import CzButton from './CzButton';
import CzIcon from './CzIcon';
export default {
  name: 'CzMenuButton',
  components: {
    CzButton,
    CzIcon
  },
  props: {
    maxWidth: {
      type: [String, Number]
    },
    minWidth: {
      type: [String, Number]
    },
    buttonTitle: {
      type: String
    },
    buttonIconSrc: {
      type: String
    },
    buttonIconSize: {
      type: Number,
      default: 18
    },
    buttonIconColor: {
      type: String
    },
    flat: {
      type: Boolean,
      default: false
    },
    buttonSmall: {
      type: Boolean,
      default: false
    },
    buttonLarge: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    clickable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    // Menu item has id (unique), title, subtitle, icon, divider, handler (indicate if the subtitle is a link)
    menuItems: {
      type: Array,
      default: () => []
    },
    menuMinWidth: {
      type: [String, Number],
      default: 220
    },
    menuMaxWidth: {
      type: [String, Number],
      default: undefined
    },
    closeOnContentClick: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String
    },
    withPaddings: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selection: null,
      menuVisible: false
    };
  },
  methods: {
    onActionClicked(item) {
      if (item.handler) {
        item.handler(item);
      } else {
        this.$emit('action:clicked', item);
      }

      // clear selection
      setTimeout(() => {
        this.selection = null;
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped></style>
