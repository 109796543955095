<template>
  <div>
    <div class="d-flex justify-space-between mb-2 pa-4">
      <v-btn-toggle
        v-if="hasDocument"
        v-model="documentType"
        mandatory
        color="button"
        rounded
      >
        <v-btn>מסמך חשבונית</v-btn>
        <v-btn>מסמך הזמנה</v-btn>
      </v-btn-toggle>
      <span v-else />
      <cz-button
        @click="downloadDocument"
        :icon-size="16"
        :icon-src="mdiDownload"
        color="info"
        text
        :title="$t('common.download')"
      />
    </div>

    <v-img
      v-if="docType && docType.startsWith('image')"
      :src="docUrl"
      contain
    />
    <pdf-viewer v-else-if="docUrl" :source="docUrl" />
  </div>
</template>

<script>
import PdfViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { saveAs } from 'file-saver';
import { CzButton } from '@/components';
import { mdiDownload } from '@mdi/js';
export default {
  name: 'SubmissionDocument',
  components: {
    PdfViewer,
    CzButton
  },
  props: {
    submission: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasDocument() {
      return !!this.submission?.relatedOrder?.orderDocument;
    },
    isManualOrder() {
      return this.submission?.relatedOrder?.creationType === 'manual';
    },
    docUrl() {
      if (this.documentType === 0) {
        return this.submission?.document?.url;
      } else if (this.documentType === 1) {
        return this.submission.relatedOrder?.orderDocument?.url;
      } else {
        return null;
      }
    },
    docType() {
      if (this.documentType === 0) {
        return this.submission?.document?.contentType;
      } else if (this.documentType === 1) {
        return this.submission.relatedOrder?.orderDocument?.contentType;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      mdiDownload,
      documentType: 0
    };
  },
  methods: {
    downloadDocument() {
      saveAs(this.docUrl, this.docName);
    }
  }
};
</script>

<style lang="scss" scoped></style>
