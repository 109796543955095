<template>
  <v-combobox
    v-if="combobox"
    v-on="$listeners"
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    :append-icon="readonly ? null : mdiChevronDown"
    :flat="readonly"
    :outlined="!readonly && outlined"
    :solo="readonly"
    :clear-icon="mdiCloseCircleOutline"
    :readonly="readonly"
    :no-data-text="$t('common.noData')"
    :loader-height="5"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="multiple"
    :chips="multiple && useChips"
    :persistent-placeholder="persistentPlaceholder"
    :item-color="itemColor"
    :return-object="returnObject"
  >
    <template v-slot:item="{ item, attrs, on }" v-if="multiple">
      <v-list-item v-on="on" v-bind="attrs">
        <template v-slot:default="{ active }">
          <v-list-item-action>
            <cz-checkbox :input-value="active" :color="itemColor" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ itemText ? item[itemText] : item }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-combobox>
  <v-autocomplete
    v-else
    v-on="$listeners"
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    :append-icon="readonly ? null : mdiChevronDown"
    :flat="readonly"
    :outlined="!readonly && outlined"
    :solo="readonly"
    :clear-icon="mdiCloseCircleOutline"
    :readonly="readonly"
    :no-data-text="$t('common.noData')"
    :loader-height="5"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="multiple"
    :item-color="itemColor"
    :small-chips="multiple"
    :chips="multiple && useChips"
    :persistent-placeholder="persistentPlaceholder"
    :return-object="returnObject"
  >
    <template v-slot:selection="{ item }" v-if="multiple && useChips">
      <cz-chip
        :text="itemText ? item[itemText] : item"
        :removeable="itemRemoveable"
        :color="chipColor"
        circleEdges
        :small="smallChips"
        class="my-1"
        :text-color="chipTextColor"
        @click:close="$emit('item-remove', item)"
      />
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs">
        <template v-slot:default="{ active }">
          <v-list-item-icon v-if="item.icon && !multiple" class="ml-2">
            <cz-icon :src="item.icon" :size="22" />
          </v-list-item-icon>
          <v-list-item-action v-if="multiple">
            <cz-checkbox :input-value="active" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ itemText ? get(item, itemText) : item }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>

    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-autocomplete>
</template>

<script>
import { mdiChevronDown, mdiClose, mdiCloseCircleOutline } from '@mdi/js';
import CzCheckbox from './CzCheckbox.vue';
import CzChip from './CzChip.vue';
import CzIcon from './CzIcon.vue';
import get from 'lodash.get';
export default {
  name: 'CzAutocomplete',
  components: {
    CzCheckbox,
    CzChip,
    CzIcon
  },
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String
    },
    chipColor: {
      type: String,
      default: 'buttonSecondary'
    },
    chipTextColor: {
      type: String,
      default: 'info'
    },
    itemColor: {
      type: String,
      default: 'button'
    },
    itemRemoveable: {
      type: Boolean,
      default: false
    },
    combobox: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    persistentPlaceholder: {
      type: Boolean
    },
    useChips: {
      type: Boolean
    }
  },
  data() {
    return {
      mdiClose,
      mdiCloseCircleOutline,
      mdiChevronDown
    };
  },
  methods: {
    get
  }
};
</script>

<style lang="scss" scoped></style>
