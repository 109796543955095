<template>
  <cz-menu-button
    v-on="$listeners"
    v-bind="$attrs"
    :menu-max-width="350"
    flat
    :color="color"
    :button-title="`${submission.documentNumber.toString()}`"
    :menuItems="menuItems"
  />
</template>

<script>
import { CzMenuButton } from '@/components';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { formatDateToDisplay } from '@/shared/services/dates.service';
export default {
  name: 'SubmissionInfoMenu',
  components: {
    CzMenuButton
  },
  props: {
    submission: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'info'
    }
  },
  computed: {
    menuItems() {
      if (!this.submission) {
        return [];
      }

      const items = [
        {
          title: this.$t('vendor.orderSubmissions.documentNumber'),
          subtitle: this.submission?.documentNumber
        },
        {
          title: this.$t('vendor.orderSubmissions.submissionDocumentType'),
          subtitle: this.submission?.documentTypeText
        },
        {
          title: this.$t('customer.orders.issueDate'),
          subtitle: formatDateToDisplay(this.submission.issueDate)
        },
        {
          title: this.$t('vendor.orderSubmissions.totalAmount'),
          subtitle: formatAmountWithCurrency(this.submission.totalAmount)
        },
        {
          title: this.$t('vendor.orderSubmissions.dispalyFullSubmission'),
          subtitle: this.$t(
            'vendor.orderSubmissions.dispalyFullSubmissionLink'
          ),
          handler: () => {
            this.$emit('show-submission');
          }
        }
      ];

      // if (this.showOrderLink) {
      //   items.push({
      //     title: this.$t('customer.orders.orderFullDisplayTitle'),
      //     subtitle: this.$t('customer.orders.orderFullDisplaySubtitle'),
      //     handler: () => {
      //       this.$emit('show-order');
      //     }
      //   });
      // }

      return items;
    }
  }
};
</script>

<style></style>
