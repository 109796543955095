import PriceType from './types/PriceType';
import UnitOfMeasureType from './types/UnitOfMeasureType';
import { mdiWeightKilogram, mdiClockOutline, mdiShapeOutline } from '@mdi/js';

export const priceTypesItems = (vue) => {
  return [
    {
      text: vue.$t('priceTypes.noPrice'),
      value: PriceType.NO_PRICE
    },
    {
      text: vue.$t('priceTypes.withPriceChangeable'),
      value: PriceType.WITH_PRICE_CHANGEABLE
    },
    {
      text: vue.$t('priceTypes.withPriceUnchangeable'),
      value: PriceType.WITH_PRICE_UNCHANGEABLE
    }
  ];
};

export const unitOfMeasureItems = (vue) => {
  return [
    {
      text: vue.$t('unitOfMeasureTypes.piece'),
      value: UnitOfMeasureType.PIECE,
      icon: mdiShapeOutline
    },
    {
      text: vue.$t('unitOfMeasureTypes.kg'),
      value: UnitOfMeasureType.KG,
      icon: mdiWeightKilogram
    },
    {
      text: vue.$t('unitOfMeasureTypes.hour'),
      value: UnitOfMeasureType.HOUR,
      icon: mdiClockOutline
    }
  ];
};
