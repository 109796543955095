<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems">
    <cz-data-table
      v-bind="submissionsTable"
      class="mt-2"
      height="calc(100vh - 240px)"
      :options.sync="filtersState.options"
      fixed-header
      item-key="_id"
    >
      <template #item.documentNumber="{ item }">
        <router-link
          :to="{
            name: 'customer-submission',
            params: {
              submissionId: item._id,
              type: 'orderless'
            }
          }"
          class="font-weight-semibold text-title button--text"
        >
          {{ item.documentNumber }}
        </router-link>
      </template>
      <template #item.documentStatusText="{ item }">
        <cz-chip
          v-if="item.documentStatusText"
          :text="item.documentStatusText"
          small
          :color="
            item.documentStatusText === SubmissionDocumentStatus.OPEN
              ? 'buttonSecondary darken-1'
              : 'primary'
          "
          dark
        />
      </template>
      <template #item.issueDate="{ item }">
        <span>{{ formatDateToDisplay(item.issueDate) }}</span>
      </template>
      <template #item.createdAt="{ item }">
        <span>{{ formatDateToDisplay(item.createdAt) }}</span>
      </template>
      <template #item.totalAmount="{ item }">
        {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
      </template>
      <template #item.vatAmount="{ item }">
        {{ formatAmountWithCurrency(item.vatAmount, item.currency) }}
      </template>
      <template #item.netAmount="{ item }">
        {{ formatAmountWithCurrency(item.netAmount, item.currency) }}
      </template>
      <template #item.approvalRound="{ item }">
        <cz-button
          :title="$t('customer.orders.viewApprovalRound')"
          color="primary"
          small
          @click.stop="showApprovalRoundForItem(item)"
          :icon-src="mdiCheckAll"
        />
      </template>
      <template #item.approverUserId="{ item }">
        <user-info-menu :user="item.approverUser" v-if="item.approverUser" />
      </template>

      <template #item.nav="{ item }">
        <cz-button
          :icon-size="30"
          text
          :icon-src="mdiChevronLeft"
          :to="{
            name: 'customer-submission',
            params: {
              submissionId: item._id,
              type: 'orderless'
            }
          }"
          color="info"
        />
      </template>
    </cz-data-table>
  </content-layout>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { CzDataTable, CzButton, CzChip } from '@/components';
import UserInfoMenu from '@/domain/auth/components/UserInfoMenu.vue';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { getOrderSubmissions } from '@/core/api';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import SubmissionDocumentStatus from '@/shared/types/SubmissionDocumentStatus';
import { mdiChevronLeft } from '@mdi/js';
export default {
  name: 'ManageInvoicesPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzDataTable,
    CzButton,
    UserInfoMenu,
    CzChip
  },

  computed: {
    ...mapFields('auth', ['accountId', 'currentUser', 'account']),
    ...mapFields('orderSubmissions', ['filtersState']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.manageInvoicesTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    // currentTab() {
    //   return this.tabs[this.filtersState.tab];
    // },
    submissionStatus() {
      return [OrderSubmissionStatus.NEW, OrderSubmissionStatus.NEW_PREDICTED];
      // if (this.filtersState.tab === 0) {
      //   return [OrderSubmissionStatus.NEW, OrderSubmissionStatus.NEW_PREDICTED];
      // } else {
      //   return [
      //     OrderSubmissionStatus.PENDING_APPROVAL,
      //     OrderSubmissionStatus.APPROVED,
      //     OrderSubmissionStatus.REJECTED,
      //     OrderSubmissionStatus.RETURNED
      //   ];
      // }
    },
    submissionTableColumns() {
      return this.submissionsTable.columns.filter(
        (item) => !this.currentTab.excludeColumns.includes(item.value)
      );
    }
  },
  created() {
    // if (this.filtersState.tab === -1) {
    //   this.filtersState.tab = this.selectedTab || 0;
    // }

    this.loadData();
  },
  data() {
    return {
      OrderSubmissionStatus,
      SubmissionDocumentStatus,
      mdiChevronLeft,
      tabs: [
        {
          id: 'pending-submissions',
          text: this.$t('customer.orderlessSubmissions.pendingSubmissions'),
          excludeColumns: ['approverUserId'],
          actions: [],
          expandable: false
        },
        {
          id: 'handeled-submissions',
          text: this.$t('customer.orderlessSubmissions.orderlessSubmissions'),
          excludeColumns: [],
          actions: [],
          expandable: true
        }
      ],
      submissionsTable: {
        loading: false,
        serverItemsLength: -1,
        columns: [
          {
            text: this.$t('customer.vendorSubmissions.submissionNumber'),
            value: 'documentNumber',
            sortable: true,
            isChildColumn: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.documentStatus'),
            value: 'documentStatusText',
            sortable: true,
            isChildColumn: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.submissionStatus'),
            value: 'statusText',
            sortable: true,
            isChildColumn: true,
            width: 150
          },
          {
            text: this.$t('common.documentType'),
            value: 'documentTypeText',
            isChildColumn: true,
            sortable: false,
            width: 120
          },

          {
            text: this.$t('vendor.orderSubmissions.netAmount'),
            value: 'netAmount',
            isChildColumn: true,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.vatAmount'),
            value: 'vatAmount',
            isChildColumn: true,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.totalAmount'),
            value: 'totalAmount',
            isChildColumn: true,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.issueDate'),
            value: 'issueDate',
            isChildColumn: true,
            sortable: true,
            width: 150
          },
          {
            text: this.$t('vendor.orderSubmissions.creationDate'),
            value: 'createdAt',
            sortable: true,
            width: 150
          },

          {
            text: this.$t('customer.orders.pendingApprovalBy'),
            value: 'approverUserId'
          },
          {
            text: '',
            value: 'nav',
            sortable: false,
            isChildColumn: true,
            align: 'center',
            width: 50
          }
        ],
        items: []
      }
    };
  },
  methods: {
    formatDateToDisplay,
    formatAmountWithCurrency,
    async loadData() {
      try {
        this.submissionsTable.items = [];
        this.submissionsTable.loading = true;

        // get all submissions for list of orders
        const { itemsPerPage, page, sortBy, sortDesc } =
          this.filtersState.options;

        const $skip = (page - 1) * itemsPerPage;
        // const orderIds = orders.map((item) => item._id);

        let query = {
          customerAccountId: this.accountId,
          status: { $in: this.submissionStatus },
          source: 'orderless',
          $limit: itemsPerPage,
          $skip
        };

        const { data: submissions, total: submissionsCount } =
          await getOrderSubmissions(query);
        this.submissionsTable.serverItemsLength = submissionsCount;
        this.submissionsTable.items = submissions;
      } finally {
        this.submissionsTable.loading = false;
      }
    },
    onSubmissionClicked(item) {
      this.$router.push({
        name: 'customer-submission',
        params: {
          submissionId: item._id,
          type: 'orderless'
        }
      });
    }
  },
  watch: {
    'filtersState.tab': {
      handler() {
        this.loadData();
      }
    },
    'filtersState.options': {
      handler() {
        this.loadData();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
