<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :open-on-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :nudge-top="-5"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        v-bind="attrs"
        v-on="on"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        :outlined="!readonly && outlined"
        :flat="readonly"
        :solo="readonly"
        :clearable="!readonly"
        :dense="dense"
        :disabled="disabled"
        :append-icon="!readonly ? mdiCalendar : null"
        @click:append="dateMenu = true"
        @click="onDateInputClicked"
        validate-on-blur
        :hide-details="hideDetails"
        readonly
        @blur="$emit('blur')"
      />
    </template>

    <div class="d-flex flex-column white">
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :max="maxDate"
        :min="minDate"
        :prev-icon="mdiChevronLeft"
        :range="range"
        :next-icon="mdiChevronRight"
        validate-on-blur
        :readonly="readonly"
        :type="type"
        color="buttonSecondary"
        locale="he-IL"
      />
      <v-divider />
      <div class="d-flex justify-end align-center pa-2" style="gap: 1rem">
        <cz-button
          title="איפוס"
          color="textPrimary"
          text
          :disabled="!date"
          @click="date = null"
        />
        <cz-button
          title="אישור"
          color="info"
          text
          :disabled="!date"
          @click="onDateSelected"
        />
      </div>
    </div>
  </v-menu>
</template>

<script>
import CzInput from './CzInput';
import CzButton from './CzButton.vue';
import { mdiCalendar, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { format, parse } from 'date-fns';

export default {
  name: 'CzNewDatePicker',
  components: {
    CzInput,
    CzButton
  },
  props: {
    /**
     * The current date value
     * in ISO8601 format YYYY-mm-dd or YYYY-mm
     */
    value: {
      type: [String, Array]
    },
    range: {
      type: Boolean
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    outlined: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    minDate: {
      type: String
    },
    maxDate: {
      type: String
    },
    hideDetails: {
      type: Boolean
    },
    openMenuOnClick: {
      type: Boolean
    },
    type: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    /**
     * if this is true then the picker will start with year then month and then day
     * to allow the user to easily pick a date
     * otherwise, the date picker will be opended in a day mode
     */
    interactive: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedDate() {
      if (!this.value || !this.value?.length) {
        return '';
      } else {
        if (Array.isArray(this.value)) {
          if (this.date.length === 2) {
            const parsedDateFrom = parse(
              this.value[0],
              'yyyy-MM-dd',
              new Date()
            );
            const parsedDateTo = parse(this.value[1], 'yyyy-MM-dd', new Date());
            return `${format(parsedDateFrom, this.dateFormat)} - ${format(
              parsedDateTo,
              this.dateFormat
            )}`;
          } else {
            return '';
          }
        } else {
          const parsedDate = parse(this.date, 'yyyy-MM-dd', new Date());
          return format(parsedDate, this.dateFormat);
        }
      }
    },
    mask() {
      if (this.type === 'month') {
        return '##/####';
      } else {
        return '##/##/####';
      }
    }
  },
  created() {
    this.date = this.value;
  },
  data() {
    return {
      mdiCalendar,
      mdiChevronLeft,
      mdiChevronRight,
      activePicker: null,
      date: null,
      localDate: null,
      inputDate: '',
      dateMenu: false
    };
  },
  methods: {
    onDateSelected() {
      this.$emit('input', this.date);
      this.dateMenu = false;
    },
    onDateInputClicked() {
      this.dateMenu = this.openMenuOnClick && !this.readonly;
    }
  }
  // watch: {
  //   date(val) {
  //     this.$emit('input', val);
  //   }
  // }
};
</script>

<style lang="scss" scoped></style>
