import { createMongoAbility } from '@casl/ability';
import Entities from './entities';

export default function defineAbilitiesForUser(user) {
  const ability = createMongoAbility(user.ability);

  return ability;

  // const { build, can } = new AbilityBuilder(createMongoAbility);

  // user.ability.forEach(({ action, subject, conditions }) => {
  //   can(action, subject, conditions);
  // });

  // return build();
}

export const userHasRoleWithName = (user, roleName) => {
  return user?.roles?.findIndex((r) => r.role === roleName) !== -1;
};

export const userHasOneOfRolesWithName = (user, roleNames) => {
  let found = false;
  for (const role of user.roles) {
    if (roleNames.includes(role.role)) {
      found = true;
      break;
    }
  }

  return found;
};

const canAccessToEntity = (entity, action, userAbilities, params = {}) => {
  if (!entity || !userAbilities) {
    return false;
  }

  let canAccess = false;

  if (Array.isArray(entity)) {
    for (const item of entity) {
      const _entity = new Entities[item](params);
      canAccess = userAbilities.can(action, _entity);
      if (canAccess) {
        break;
      }
    }
  } else {
    const _entity = new Entities[entity](params);
    canAccess = userAbilities.can(action, _entity);
  }

  return canAccess;
};

export { canAccessToEntity };
