<template>
  <v-badge
    :value="count > 0 ? true : false"
    left
    color="info"
    :offset-y="14"
    :offset-x="20"
    :content="count"
  >
    <cz-button
      :title="$t('common.filters')"
      :icon-src="count > 0 ? mdiFilterCheckOutline : mdiFilterOutline"
      :color="color"
      @click="$emit('click')"
      class="info--text font-weight-semibold"
      :loading="loading"
    />
  </v-badge>
</template>

<script>
import { mdiFilterCheckOutline, mdiFilterOutline } from '@mdi/js';
import CzButton from './CzButton.vue';
export default {
  name: 'CzFilterButton',
  components: {
    CzButton
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean
    },
    color: {
      type: String,
      default: 'buttonSecondary'
    }
  },
  data() {
    return {
      mdiFilterCheckOutline,
      mdiFilterOutline
    };
  }
};
</script>

<style lang="scss" scoped></style>
