import { endOfDay, parse, startOfDay, format } from 'date-fns';
import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';

export const filtersWithValues = (filters, format = false) => {
  let values = filters?.filter((item) => {
    return !isEmpty(item.value?.toString());
  });

  if (format) {
    values = values.map((item) => {
      return {
        ...item,
        value: formatItemForDisplay(item, filters)
      };
    });
  }

  return values;
};

export const activeFiltersCount = (filters) => {
  if (!hasActiveFilters(filters)) {
    return 0;
  }

  return filtersWithValues(filters).length;
};

export const formatItemForDisplay = (item, filters) => {
  let _items = [];
  const itemValue = item.itemValue || 'value';
  const itemText = item.itemText || 'text';

  switch (item.type) {
    case 'vendors':
      return item.value?.businessName;
    case 'users':
      return item.value?.fullName;
    case 'datepicker':
      if (Array.isArray(item.value)) {
        if (item.value.length === 2) {
          return `${format(new Date(item.value[0]), 'dd/MM/yyyy')} - ${format(
            new Date(item.value[1]),
            'dd/MM/yyyy'
          )}`;
        } else {
          return format(new Date(item.value[0]), 'dd/MM/yyyy');
        }
      } else {
        return format(item.value, 'dd/MM/yyyy');
      }
    case 'autocomplete':
    case 'tags':
      if (item.itemsHandler) {
        _items = item.itemsHandler(filters);
      } else {
        _items = item.items;
      }
      if (item.multiple) {
        return _items
          .filter((i) => item.value.includes(i[itemValue]))
          .map((i) => i[itemText])
          .toString();
      } else {
        const _item = _items.find((i) => i[itemValue] === item.value);
        if (_item) {
          return _item[itemText];
        } else {
          return '';
        }
      }

    default:
      return item.valueText
        ? item.valueText(item.value, item.itemsHandler(filters))
        : item.value;
  }
};

export const formatFiltersForServer = (filters) => {
  const _filters = cloneDeep(filters);

  // get only filters which has values
  // format specific filter types (e.g. date) before send them to the server
  if (!_filters?.length) {
    return [];
  }
  const filtersWithValues = _filters.filter((item) => {
    return !isEmpty(item.value?.toString()); //!!item.value && item.value?.length
  });
  filtersWithValues.forEach((item) => {
    if (item.type == 'vendors') {
      item.value = item.value._id;
    }

    if (item.type === 'users') {
      item.value = item.value._id;
    }

    if (item.operator) {
      switch (item.operator) {
        case 'ge':
          item.value = { $gte: item.value };
          break;
        case 'le':
          item.value = { $lte: item.value };
          break;
        case 'gt':
          item.value = { $gt: item.value };
          break;
        case 'lt':
          item.value = { $lt: item.value };
          break;
        case 'in':
          item.value = { $in: item.value };
          break;
        case 'ne':
          item.value = { $ne: item.value };
          break;
        case 'bt':
          if (item.value?.length === 2) {
            const from = startOfDay(
              parse(item.value[0], 'yyyy-MM-dd', new Date())
            );
            const to = endOfDay(parse(item.value[1], 'yyyy-MM-dd', new Date()));
            item.value = { $gte: from, $lte: to };
          } else if (item.value?.length === 1) {
            item.value = { $gte: item.value[0] };
          }
          break;
      }
    }
  });

  return filtersWithValues;
};

export const getFiltersWithValues = (filters) => {
  return filters?.filter((item) => {
    if (Array.isArray(item.value)) {
      return item.value.length > 0;
    } else {
      return !!item.value;
    }
  });
};

export const hasActiveFilters = (filters) => {
  return getFiltersWithValues(filters)?.length > 0;
};
